export default {
    namespaced: true,
    state: {
        questions: [],
        completeNumber:0,
        index:0,
    },
    mutations: {
        setQuestions(state, questions) {
            state.questions = questions;
        },
        setCompleteNumber(state, completeNumber) {
            state.completeNumber = completeNumber;
        },
        setIndex(state, index) {
            state.index = index;
        }
    },
    actions: {
        updateQuestions({ commit }, questions) {
            commit('setQuestions', questions);
        },
        updateCompleteNumber({ commit }, questions) {
            commit('setCompleteNumber', questions);
        },
        updateIndex({ commit }, questions) {
            commit('index', questions);
        }
    },
    getters: {
        questions: state => state.questions,
        completeNumber: state => state.completeNumber,
        index: state => state.index
    }
}